/* eslint-disable react/jsx-no-target-blank */
import { useHeader, useChangeTheme } from "hooks";
import {
  ChangeThemeContainer,
  StyledDrawer,
  MaterialUISwitch,
  FavoriteContainer,
  Button,
  BadgeButtonContainer,
  BadgeButton,
} from "./SideMenu.style";
import {
  CardActionArea,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useFavorites } from "hooks/useFavorites";
import { useLocation, useNavigate } from "react-router-dom";
import { ALLERGENES, FAVORITE } from "constants/paths";
import { Icon } from "@iconify/react";
import { SelectLanguage } from "../SelectLanguages/SelectLanguage";
import useTranslation from "i18n";
import { useEffect } from "react";
import { PrenotoLogo } from "assets/prenoto";

const SideMenu = () => {
  //hooks
  const { sideMenu, closeSideMenu } = useHeader();
  const { isDark, toggleDarkMode } = useChangeTheme();
  const { favorites } = useFavorites();
  const navigate = useNavigate();
  const theme = useTheme();
  const { i18next } = useTranslation();
  const { pathname } = useLocation();
  //function
  const handleChangeTheme = () => {
    localStorage.setItem("isDarkTheme", JSON.stringify(!isDark));
    toggleDarkMode();
  };

  const goToFavorites = () => {
    closeSideMenu();
    navigate(FAVORITE);
  };
  const goToAllergenes = () => {
    closeSideMenu();
    navigate(ALLERGENES);
  };

  useEffect(() => {
    closeSideMenu();
  }, [pathname]);

  return (
    <StyledDrawer open={sideMenu}>
      <ChangeThemeContainer>
        <MaterialUISwitch checked={!isDark} onChange={handleChangeTheme} />
      </ChangeThemeContainer>

      <FavoriteContainer>
        <Button disabled={favorites.length === 0}>
          <CardActionArea
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            disabled={favorites.length === 0}
            onClick={goToFavorites}
          >
            {favorites.length > 0 ? (
              <BadgeButtonContainer>
                <BadgeButton>{favorites.length}</BadgeButton>
              </BadgeButtonContainer>
            ) : null}
            <Typography variant="h6">{i18next.t("preferiti")}</Typography>
          </CardActionArea>
        </Button>

        <Button disabled={false}>
          <CardActionArea
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={goToAllergenes}
          >
            <Typography variant="h6">{i18next.t("allergeni-side")}</Typography>
          </CardActionArea>
        </Button>
        <SelectLanguage type="select" />
        <Typography sx={{ textAlign: "center", marginTop: "10%" }}>
          MAMÀ PIZZERIA <br /> Viale Lepanto n.8 - Porto Recanati (MC)
          <br />
          <a
            href="tel: +393773516146"
            style={{ color: "currentColor", textDecoration: "none" }}
          >
            Tel: +39 377 35 16 146
          </a>
        </Typography>
        <Stack flexDirection="row" gap="10px" justifyContent="center">
          <a href="https://instagram.com/mama.pizzeriacontemporanea">
            <IconButton size="large">
              <Icon icon="teenyicons:instagram-outline" />
            </IconButton>
          </a>
          <a href="https://facebook.com/mama.pizzeriacontemporanea">
            <IconButton size="large">
              <Icon icon="ri:facebook-fill" />
            </IconButton>
          </a>
        </Stack>
        <Stack alignItems="center" direction="row" gap="5px" justifyContent="center" sx={{ width: "100%" }}>

              <Typography lineHeight={0} sx={{position: 'relative', bottom: '4px'}} variant="caption" fontWeight="500">Created with</Typography>
              <a href="https://prenotoapp.it" target="_blank" rel="noreferrer"><PrenotoLogo width="60px" /></a>
   
        </Stack>
      </FavoriteContainer>
    </StyledDrawer>
  );
};

export { SideMenu };
