import { Logo } from "assets";
import { LanguageContainer, PrenotoLogoContainer } from "./SelectLanguage.style";
import { useEffect, useRef } from "react";
import gsap, { Power1 } from "gsap";
import { Button, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { allLangs } from "config";
import { ILang, Locales } from "type/intl";
import { useNavigate } from "react-router-dom";
import { useGlobalSelector } from "stores/global";
import { HOME } from "constants/paths";
import useTranslation from "i18n";
import { PrenotoLogo } from "assets/prenoto";

const SelectLanguage = ({ type }: { type: "page" | "select" }) => {
  const LogoRef = useRef(null);
  const textRef = useRef(null);
  const presentationRef = useRef(null);
  const navigate = useNavigate();
  const { setActiveLocale, activeLocale } = useGlobalSelector();

  const { i18next } = useTranslation();

  useEffect(() => {
    gsap.fromTo(
      LogoRef.current,
      {
        scale: 0,
        duration: 0.2,
        opacity: 0,
        delay: 0.5,
      },
      {
        scale: 1,
        duration: 0.2,
        opacity: 1,
        delay: 0.5,
        ease: Power1.easeIn,
      }
    );
    gsap.fromTo(
      presentationRef.current,
      {
        y: 10,
        duration: 0.5,
        opacity: 0,
        delay: 1,
      },
      {
        y: 0,
        duration: 0.5,
        opacity: 1,
        delay: 1,
        ease: Power1.easeIn,
      }
    );
    gsap.fromTo(
      textRef.current,
      {
        y: 10,
        duration: 0.5,
        opacity: 0,
        delay: 1.5,
      },
      {
        y: 0,
        duration: 0.5,
        opacity: 1,
        delay: 1.5,
        ease: Power1.easeIn,
      }
    );
  }, []);

  const onClick = (locale: Locales) => {
    localStorage.setItem("activeLocaleMama", JSON.stringify(locale));
    setActiveLocale(locale);
    navigate(HOME);
  };

  const renderComponent = () => {
    switch (type) {
      case "page": {
        return (
          <LanguageContainer>
            <Logo width="100px" logoRef={LogoRef} />
            <Stack gap="5px" sx={{ width: "70%" }} ref={presentationRef}>
              {allLangs.map((l: ILang) => (
                <Button
                  fullWidth
                  variant="outlined"
                  size="large"
                  startIcon={<Icon icon={l.icon} />}
                  onClick={() => onClick(l.value)}
                >
                  {l.label}
                </Button>
              ))}
            </Stack>
            <PrenotoLogoContainer>
              <Typography lineHeight={0} sx={{position: 'relative', bottom: '4px'}} variant="caption" fontWeight="500">Created with</Typography>
              <a href="https://prenotoapp.it" target="_blank" rel="noreferrer"><PrenotoLogo width="60px" /></a>
            </PrenotoLogoContainer>
          </LanguageContainer>
        );
      }
      case "select": {
        return (
          <TextField
            select
            value={activeLocale}
            label={i18next.t("lingua")}
            sx={{
              "& .MuiSelect-select": {
                display: "flex",
                alignItems: "center",
                gap: "10px",
              },
            }}
          >
            {allLangs.map((l: ILang) => (
              <MenuItem
                onClick={() => {
                  localStorage.setItem(
                    "activeLocaleMama",
                    JSON.stringify(l.value)
                  );

                  setActiveLocale(l.value);
                }}
                key={l.value}
                value={l.value}
                sx={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <Icon icon={l.icon} fontSize="30px" />{" "}
                <Typography variant="body1">{l.label}</Typography>
              </MenuItem>
            ))}
          </TextField>
        );
      }
    }
  };

  return renderComponent();
};

export { SelectLanguage };
