import { Box, BoxProps } from "@mui/material";
import { CSSProperties } from "react";

interface Props extends BoxProps {
  color?: string;
  style?: CSSProperties;
  width: string;
}

const PrenotoLogo = ({ width, color = "#fff", ...other }: Props) => {
  return (
    <Box {...other}>
      <svg
      style={{transition: 'all 0.5s ease',}}
        width={width}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 731.11 201.87"
      >
        <g id="Livello_2" data-name="Livello 2" fill={color}>
          <g id="Livello_1-2" data-name="Livello 1">
            <path d="M87.6,42.33a51.66,51.66,0,0,1,18.59,21.9q6.61,14.07,6.61,32.67t-6.61,32.42a50.63,50.63,0,0,1-18.47,21.41Q75.86,158.31,60,158.31q-14,0-24.35-6.12a39.26,39.26,0,0,1-15.78-17.37v57.5a9.46,9.46,0,0,1-2.57,7A9.77,9.77,0,0,1,10,201.87a9.89,9.89,0,0,1-7.34-2.7A9.24,9.24,0,0,1,0,192.32V44.78a9.58,9.58,0,0,1,2.69-7.1A9.93,9.93,0,0,1,10,35a9.52,9.52,0,0,1,7.22,2.69,9.88,9.88,0,0,1,2.57,7.1V58A39.36,39.36,0,0,1,35.6,40.62Q46,34.5,60,34.5A49.44,49.44,0,0,1,87.6,42.33ZM83,130.17q9.54-11.74,9.54-33.27T83,63.25Q73.41,51.15,56,51.14T29.24,62.88q-9.42,11.74-9.42,33.53t9.42,33.64Q38.66,141.92,56,141.92T83,130.17Z" />
            <path d="M197,43.31a8.74,8.74,0,0,1-2.2,6.24q-2.21,2.33-7.59,2.81l-7.34.74q-16.4,1.47-24.35,11.62a37.57,37.57,0,0,0-8,23.86V148q0,4.89-2.69,7.34a11.47,11.47,0,0,1-14.31-.13,9.25,9.25,0,0,1-2.82-7.21V44.78a9.08,9.08,0,0,1,2.94-7.22A10.41,10.41,0,0,1,137.75,35a9,9,0,0,1,9.3,9.54V58.24a37.23,37.23,0,0,1,14.56-16.4,49.4,49.4,0,0,1,21.66-6.61l3.42-.24Q197,34.25,197,43.31Z" />
            <path d="M293.86,131.15a8.15,8.15,0,0,1,2,5.63q0,6.12-8.56,11.26a76.36,76.36,0,0,1-37.68,10.27q-27.66,0-43.68-16.39t-16-45.27q0-18.34,7.1-32.42a53,53,0,0,1,19.94-21.9,55.06,55.06,0,0,1,29.24-7.83q23.25,0,37,15.17t13.7,41.11c0,3.26-.65,5.63-2,7.09s-3.42,2.21-6.36,2.21H209.69q2.2,42.09,39.89,42.08A46.58,46.58,0,0,0,266,139.59a105.64,105.64,0,0,0,14.68-7q6.36-3.68,8.57-3.67A6,6,0,0,1,293.86,131.15ZM221.32,59.7q-9.44,9.8-11.14,27.65h69.25q-.49-18.1-9.05-27.77t-24-9.66Q230.72,49.92,221.32,59.7Z" />
            <path d="M414,81.73V148c0,3.1-.85,5.5-2.57,7.21s-4.12,2.57-7.21,2.57a10.15,10.15,0,0,1-7.34-2.57,9.46,9.46,0,0,1-2.7-7.21V83q0-16.64-6.48-24.23T367,51.14q-16.39,0-26.18,10t-9.78,27.16V148a8.93,8.93,0,0,1-9.79,9.78,10.15,10.15,0,0,1-7.34-2.57,9.46,9.46,0,0,1-2.69-7.21V44.78a9.58,9.58,0,0,1,2.69-7.1A9.93,9.93,0,0,1,321.27,35a9.2,9.2,0,0,1,7,2.69,9.54,9.54,0,0,1,2.57,6.85V57A40.21,40.21,0,0,1,347.2,40.25a49.24,49.24,0,0,1,24-5.75Q414,34.5,414,81.73Z" />
            <path d="M455.23,150.73a50.61,50.61,0,0,1-19.82-21.66q-7-14.07-7-32.66t7-32.67a50.53,50.53,0,0,1,19.82-21.65Q468.09,34.5,485,34.5t29.85,7.59a50.18,50.18,0,0,1,19.94,21.65q7,14.07,7,32.67t-7,32.66a50.26,50.26,0,0,1-19.94,21.66q-13,7.58-29.85,7.58T455.23,150.73ZM512,130.3q9.42-11.63,9.42-33.89,0-21.78-9.54-33.65T485,50.89q-17.37,0-26.91,11.87T448.5,96.41q0,22,9.42,33.76t27,11.75Q502.59,141.92,512,130.3Z" />
            <path d="M620,150a7,7,0,0,1-3.06,6.24q-3.06,2.09-9.18,1.59l-6.6-.49q-19.58-1.47-28.88-11.74T563,114.51V53.1h-16.4A9.41,9.41,0,0,1,540.14,51a7,7,0,0,1-2.33-5.51,7.7,7.7,0,0,1,2.33-5.87,9,9,0,0,1,6.48-2.2H563V9.79a9.48,9.48,0,0,1,2.69-7.22A10.2,10.2,0,0,1,573.05,0a9.88,9.88,0,0,1,7.1,2.57,9.52,9.52,0,0,1,2.69,7.22V37.44H610a8.72,8.72,0,0,1,6.24,2.2,7.73,7.73,0,0,1,2.32,5.87A7.06,7.06,0,0,1,616.24,51,9.08,9.08,0,0,1,610,53.1H582.84v62.39q0,13.45,5.5,19.45t16.52,6.73l6.61.49Q620,142.9,620,150Z" />
            <path d="M644.62,150.73a50.55,50.55,0,0,1-19.82-21.66q-7-14.07-7-32.66t7-32.67a50.47,50.47,0,0,1,19.82-21.65q12.84-7.59,29.73-7.59t29.85,7.59a50.3,50.3,0,0,1,19.94,21.65q7,14.07,7,32.67t-7,32.66a50.38,50.38,0,0,1-19.94,21.66q-13,7.58-29.85,7.58T644.62,150.73Zm56.77-20.43q9.4-11.63,9.42-33.89,0-21.78-9.55-33.65T674.35,50.89q-17.39,0-26.92,11.87t-9.54,33.65q0,22,9.42,33.76t27,11.75Q692,141.92,701.39,130.3Z" />
          </g>
        </g>
      </svg>
    </Box>
  );
};

export { PrenotoLogo };
